import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
// import Layout from '../components/layout'
import SEO from '../components/seo'
import OperatingHours from '../components/service/operatingHours'

const OperatingHoursPage = ({ intl }) => (
  <>
    <SEO title={intl.formatMessage({ id: 'pages.operatinghours.title' })} />
    <div
      style={{
        maxWidth: `800px`,
        margin: `0 auto`,
      }}
    >
      <OperatingHours />
    </div>
  </>
)

export default injectIntl(OperatingHoursPage)
